<template>
  <div class="container">
    <KTCodePreview title="Danh sách phiếu định giá đã xoá">
      <template v-slot:preview>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          
          <b-col v-if="checkPermissions(['TRADE_IN_SHOW_ALL_STORE'])">
            <BDropdownCustom
              v-model="selectedStore"
              :options="filteredStoreOptions"
              :placeholder="'cửa hàng'"
              :searchable="true"
              :matchKey="['name']"
              :limit="5"
            />
          </b-col>
          <b-col>
            <div class="d-flex justify-content-start">
              <b-input
                placeholder="Tìm kiếm theo tên, mã, IMEI sản phẩm"
                v-model="searchProduct"
                class="form-control form-control-sm"
                v-on:keyup.enter="onFilter()"
              ></b-input>
            </div>
          </b-col>
          <b-col>
            <b-input
              placeholder="Tìm kiếm theo tên, SĐT KH"
              v-model="searchCustomer"
              class="form-control form-control-sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Trạng thái --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
          <b-col md="1">
            <b-button
              class="btn-sm w-100 fw-600"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              hover
              bordered
              :busy="onLoading"
              responsive
              class="w-100"
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="6"
                ></vcl-table>
              </template>
              <template v-slot:cell(type)="row">
                <div style="text-align: center">
                  <i
                    v-if="row.item.tradeInType === TRADE_IN_TYPE.TRADE_IN"
                    class="fa fa-retweet icon-color text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                  <i
                    v-else-if="row.item.tradeInType === TRADE_IN_TYPE.BUYING_OLD_ITEMS"
                    class="fa fa-cart-arrow-down text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                  <i
                    v-else
                    class="fa fa-exchange-alt text-dark"
                    v-b-tooltip.hover
                    :title="row.item.tradeInTypeName"
                  ></i>
                </div>
              </template>
              <template v-slot:cell(customer)="row">
                <p class="mb-0 font-weight-bolder">
                  {{ row.item.customerName }}
                </p>
                <p class="mb-0">
                  <a
                    class="font-weight-bold"
                    v-bind:href="getLinkDocCustomer(row.item)"
                    target="_self"
                  >
                    {{ row.item.customerPhone }}
                  </a>
                </p>
              </template>
              <template v-slot:cell(productName)="row">
                <p class="mb-0">
                  {{ row.item.productName }}
                </p>
                <p class="text-imei-no mb-0">IMEI: {{ row.item.imeiCode }}</p>
              </template>
              <template v-slot:cell(estimatePrice)="row">
                <div>
                  <span v-text="convertPrice(row.item.estimatePrice)"></span>
                </div>
              </template>
              <template v-slot:cell(finalPrice)="row">
                <div>
                  <span v-text="convertPrice(row.item.finalPrice)"></span>
                </div>
              </template>
              <template v-slot:cell(updatedBy)="row">
                <div class="d-flex flex-column">
                  <span v-text="row.item.updatedBy"></span>
                  <span v-text="row.item.updatedAt"></span>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark font-weight-bold">
                  Tổng số:
                  {{ convertPrice(totalRow) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  :link-gen="linkGen"
                  v-show="numberOfPage >= 2"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { convertPrice } from '@/utils/common';
import { TRADE_IN_TYPE, TRADE_IN_STATUS } from '@/utils/enum';
import { TRADE_IN_STATUS_OPTIONS } from '@/utils/constants';
import timeUtils from '@/utils/date';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { checkPermissions } from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    BDropdownCustom,
  },
  created() {
    this.fetchStoreByUser();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu định giá đã xoá', route: 'deleted-trade-in' },
      { title: 'Danh sách phiếu định giá đã xóa' },
    ]);
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  data() {
    return {
      stores: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          tdClass: 'td-id',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          tdClass: 'td-type',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          tdClass: 'td-store-name',
          thClass: 'table-header',
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          tdClass: 'td-customer',
          thClass: 'table-header',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          tdClass: 'td-product-name',
        },
        {
          key: 'estimatePrice',
          label: 'Giá thu dự kiến',
          sortable: false,
          tdClass: 'td-estimate-price text-right',
          thClass: 'table-header',
        },
        {
          key: 'finalPrice',
          label: 'Giá thu cuối cùng',
          sortable: false,
          tdClass: 'td-final-price text-right',
          thClass: 'table-header',
        },
        {
          key: 'hasUploadImage',
          label: 'Hình ảnh',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'table-header',
        },
        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
          tdClass: 'td-note',
          thClass: 'table-header',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          tdClass: 'td-status',
          thClass: 'table-header',
        },
        {
          key: 'updatedBy',
          label: 'Người xoá',
          sortable: false,
          tdClass: 'td-updated-by',
        },
      ],
      items: [],
      onLoading: false,
      searchId: '',
      searchCustomer: '',
      searchProduct: '',
      filteredStoreOptions: [],
      selectedStore: [],
      selectedStatus: null,
      listStatus: TRADE_IN_STATUS_OPTIONS,
      TRADE_IN_TYPE,
      TRADE_IN_STATUS,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      totalRow: 0,
      dpConfigs: timeUtils.DP_CONFIG,
      dateFrom: null,
      dateTo: null,
    };
  },
  methods: {
    checkPermissions,
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.stores = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.stores.push(option);
            if (!data.data.viewAll) {
              const index = this.stores.findIndex(
                (item) => item.code == element.id,
              );
              if (index > -1) {
                this.stores[index].checked = true;
                this.selectedStore.push(this.stores[index]);
              }
            }
          });
          this.filteredStoreOptions = [...this.stores];

          this.fetchData();
        }
      });
    },
    getLinkDocCustomer(item) {
      return `#/customers/update-customer?id=${item.customerId}&phoneNo=${item.customerPhone}`;
    },
    convertPrice,
    onFilter() {
      this.fetchData();
    },
    fetchData() {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const fromDate = this.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD 00:00:00')
        : null;
      const toDate = this.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;

      let listStoreId = [];
      if (this.selectedStore.length) {
        listStoreId = this.selectedStore.map(({ code }) => code);
      }
      const param = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct ? this.searchProduct.trim() : '',
        searchCustomer: this.searchCustomer ? this.searchCustomer.trim() : '',
        fromDate: fromDate,
        toDate: toDate,
        store: listStoreId,
        selectedStatus: this.selectedStatus,
        searchId: this.searchId ? this.searchId.trim() : '',
        deleted: 1,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('tradeIn', paramQuery).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        this.totalRow = data.data.total_row;
        this.items = data.data.data.map((element) => {
          return {
            id: element.id,
            customerId: element.customerId,
            customerName: element.customerName,
            customerPhone: element.customerPhone,
            productName: element.productName,
            imeiCode: element.imeiCode,
            estimatePrice: element.estimationBuydingPrice,
            finalPrice: element.finalBuyingPrice,
            date: element.date,
            storeName: element.storeName,
            status: element.status,
            createdBy: element.createdBy,
            statusName: element.statusName,
            tradeInType: element.tradeInType,
            tradeInTypeName: element.tradeInTypeName,
            stockSlipCode: element.stockSlipCode,
            stockSlipId: element.stockSlipId,
            note: element.note,
            hasUploadImage: element.hasUploadImage,
            updatedBy: element.updatedBy,
            updatedAt: element.updatedAt,
          };
        });
      });
      this.onLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container ::v-deep {
  table {
    .td-id {
      width: 5%;
    }
    .td-product-name {
      width: 20%;
    }
    .table-header {
      width: 7%;
    }
    .td-updated-by {
      width: 10%;
    }
    .td-type {
      width: 1%;
    }
    th {
      text-align: center;
      vertical-align: middle;
    }
  }
}
</style>